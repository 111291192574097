import React from 'react';
import './SecondaryButton.scss';
import { Button } from 'react-bootstrap';

const SecondaryButton = ({ text, onClick, disabled }) => {
  return (
    <>
      <Button
        disabled={disabled}
        className="SecondaryButton"
        block
        onClick={onClick}
      >
        {text}
      </Button>
    </>
  );
};

export { SecondaryButton };