import React from 'react';
import './PrimaryButton.scss';
import { Button } from 'react-bootstrap';

const PrimaryButton = ({ text, onClick, outline, row, icon, disabled }) => {
  const buttonDisabled = disabled ? true : false;

  return (
    <>
      <Button
        disabled={buttonDisabled}
        className={
          `PrimaryButton
        ${outline === true ? 'PrimaryButton-outline' : ''}
        ${row === true ? 'PrimaryButton-row' : ''}`
        }
        block
        onClick={onClick}
      >
        {icon &&
          <img
            alt="button-icon"
            src={icon}
            className="button-icon"
          />
        }
        {text}
      </Button>
    </>
  );
};

export { PrimaryButton };