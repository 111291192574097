import {
  SET_SELECTED_LOCATION,
  
} from '../actions';

export const setSelectedLocation = (selectedLocation) => {
  return async dispatch => {
    // Dispatch Location
    dispatch({ type: SET_SELECTED_LOCATION, payload: selectedLocation });
  }
}