import React, { Component } from 'react';
import lottie from 'lottie-web';
import LoadingData from '../../assets/animations/CFASpinner.json';

class CFASpinner extends Component {
  constructor(props) {
    super(props);
    this.loadingRef = React.createRef();

    this.state = {
      margin: props.margin
    }
  }

  componentDidMount() {
    lottie.loadAnimation({
      container: this.loadingRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: LoadingData,
    });
  }

  componentWillUnmount() {
    lottie.destroy();
  }

  render() {
    return (
      <div
        ref={this.loadingRef}
        className="bounceIn"
        style={{
          width: '85px',
          margin: `${this.state.margin ? '50% auto' : '10% auto'}`
        }}
      >
      </div>
    );
  }
}

export default CFASpinner;