import React, { Component } from 'react';
import { connect } from 'react-redux';
import './HistoryPage.scss';
import I18n from '../../../../i18n/utils';
import { parseHTML } from '../../../utils';
import { Container, Collapse, Row } from 'react-bootstrap';
import { Card, PrimaryButton, ListDivider, ModalPopup } from '../../../components/common';
import { AccordionList } from '../../../components/common';
import { sendHealthPromptHistoryEmail, sendHealthPromptLaborReportEmail } from '../../../actions';

class HistoryPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showInfo: true,
      loading: props.loading,
      healthPrompts: null,
      showDatePickerModal: false,
      userToken: props.userToken
    };

    this.showLaborReportDatePicker = this.showLaborReportDatePicker.bind(this);
    this.closeLaborReportDatePicker = this.closeLaborReportDatePicker.bind(this);
    this.sendLaborReportEmail = this.sendLaborReportEmail.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    let update = {};

    // Update User Token
    if (props.userToken !== state.userToken) {
      update.userToken = props.userToken;
    }

    // Update Alerts
    if (props.alerts !== state.alerts) {
      update.alerts = props.alerts;
    }

    // Update Health Prompts
    if (props.healthPrompts !== state.healthPrompts) {
      update.healthPrompts = props.healthPrompts;
    }

    // Update Loading
    if (props.loading !== state.loading) {
      update.loading = props.loading;
    }

    return update;
  }

  showLaborReportDatePicker() {
    this.setState({ showDatePickerModal: true });
  }

  closeLaborReportDatePicker() {
    this.setState({ showDatePickerModal: false });
  }

  sendLaborReportEmail(data) {
    // Close Modal
    this.closeLaborReportDatePicker();
    const { startDate, endDate } = data;
    this.props.sendHealthPromptLaborReportEmail(startDate, endDate);
  }

  async componentDidUpdate() {
  }

  toggleInfo() {
    const showInfo = !this.state.showInfo;
    this.setState({ showInfo });
  }

  render() {
    return (
      <Container className="HistoryPage">
        <Card className="Card"
          content={
            <>
              <Row className="row" onClick={this.toggleInfo}>
                <label className="card-label">{`${this.state.showInfo ? 'Less' : 'More'} Info`}</label>
                <img
                  alt=""
                  src={require('../../../assets/icons/down-arrow.svg')}
                  className={`arrow-icon ${this.state.showInfo ? 'upside-down' : ''}`}
                />
              </Row>
              <Collapse in={this.state.showInfo}>
                <div>
                  <Row>
                    <p>{parseHTML(I18n.t('HISTORY_ALERT_HISTORY_MESSAGE'))}</p>
                  </Row>
                  {
                    this.state.userToken &&
                    this.state.userToken.isOperator &&
                    <Row>
                      <PrimaryButton
                        text={I18n.t('EMAIL_90_DAY_BUTTON')}
                        onClick={this.props.sendHealthPromptHistoryEmail}
                      />
                      <PrimaryButton
                        text={I18n.t('EMAIL_LABOR_REPORT_BUTTON')}
                        onClick={this.showLaborReportDatePicker}
                      />
                      {
                        this.state.showDatePickerModal &&
                        <ModalPopup
                          type="date-picker"
                          onSubmit={this.sendLaborReportEmail}
                          onClose={this.closeLaborReportDatePicker}
                        />
                      }
                    </Row>
                  }
                </div>
              </Collapse>
            </>
          }>
        </Card>
        <ListDivider text={I18n.t('HISTORY_PAST_LABEL')} page={'history'} showIcon />
        {
          (this.state.healthPrompts &&
            this.state.healthPrompts.history) ?
            <AccordionList data={this.state.healthPrompts.history} />
            :
            <Card
              content={
                <h6>{I18n.t('NO_HEALTH_PROMPTS_MESSAGE')}</h6>
              } />
        }
      </Container>
    )
  }
};

const mapStateToProps = state => {
  const { userToken } = state.auth;
  const { loading, healthPrompts } = state.healthPrompt;
  return { userToken, loading, healthPrompts };
}

export default connect(
  mapStateToProps,
  {
    sendHealthPromptHistoryEmail,
    sendHealthPromptLaborReportEmail
  }
)(HistoryPage);