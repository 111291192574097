import React from 'react';
import './TabButtons.scss';
import { Button, ButtonGroup } from 'react-bootstrap';

const TabButtons = ({ tabText1, tabText2, selectedTabIndex, onClick }) => {

  const handleClick = (idx) => {
    if (selectedTabIndex === idx) return;
    onClick();
  }

  return (
    <>
      <ButtonGroup className="TabButtons">
        <Button
          className={`tab-button tab1 ${selectedTabIndex === 0 ? 'selected' : ''}`}
          onClick={() => handleClick(0)}
        >
          {tabText1}
        </Button>
        <Button
          className={`tab-button tab2 ${selectedTabIndex === 1 ? 'selected' : ''}`}
          onClick={() => handleClick(1)}
        >
          {tabText2}
        </Button>
      </ButtonGroup>
    </>

  );
};

export { TabButtons };