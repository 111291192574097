
import React, { useContext } from "react";
import FlatList from 'flatlist-react';
import I18n from '../../../../i18n/utils';
import { parseHTML } from '../../../utils';
import { Accordion, useAccordionToggle, Row, Col } from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { Card } from '../../common';
import HealthPromptAlert from '../../HealthPromptAlert/HealthPromptAlert';

import "./AccordionList.scss";

const ContextAwareToggle = ({ children, eventKey, callback, item }) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;
  const numOfAlerts = item.times.length;

  return (
    <Row className="row" onClick={decoratedOnClick}>
      <label className="card-label">{`${item.date_string}`}
        <span className="alerts-number">{` (${numOfAlerts})`}</span>
      </label>
      <img
        alt=""
        src={require('../../../assets/icons/down-arrow.svg')}
        className={`arrow-icon ${isCurrentEventKey ? 'upside-down' : ''}`}
      />
    </Row>
  );
}

const renderItem = (item, idx) => {
  return (
    <div key={idx}>
      <Card
        className="Alert"
        content={
          <>
            <ContextAwareToggle eventKey={idx} item={item} />
            <Accordion.Collapse eventKey={idx}>
              <div>
                <Row className="no-padding">
                  <Col xs="2">
                    <img
                      alt="team-member"
                      src={require('../../../../assets/team_member_icon.png')}
                      className="team-member-icon"
                    />
                  </Col>
                  <Col xs="10">
                    <h4 className="health-prompt-message">{parseHTML(I18n.t('HEALTH_PROMPT_LIST_MESSAGE'))}</h4>
                  </Col>
                </Row>
                <Row className="times-container no-padding">
                  {
                    item.times.map((alert, i) => {
                      return (
                        <HealthPromptAlert key={i} alert={alert} first={i === 0 ? true : false} />
                      )
                    })
                  }
                </Row>
              </div>
            </Accordion.Collapse>
          </>
        }>
      </Card>
    </div>
  )
}

const AccordionList = (props) => {
  return (
    <Accordion
      defaultActiveKey="0"
      className="AccordionList">

      <FlatList
        list={props.data}
        renderItem={renderItem}
        renderWhenEmpty={() => <label>No Alerts</label>}
      />
    </Accordion>
  );
}

export { AccordionList }