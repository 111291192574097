import Cookies from 'js-cookie';
import { store } from '../store';
import { setUserSession } from '../actions';

export const hasAuthToken = () => {
  try {
    const accessToken = Cookies.get('at');
    const hasToken = Cookies.get('at') == null ? false : true;
    if (hasToken) store.dispatch(setUserSession(accessToken));
    return hasToken;
  } catch (err) {
    console.log(err);
    return false;
  }
}

