import {
  SET_SELECTED_LOCATION,
} from '../actions';

const initialState = {
  selectedLocation: null,
  loading: false,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_LOCATION:
      return { ...state, loading: false, selectedLocation: action.payload }
    default:
      return state;
  }
};