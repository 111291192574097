import { showFailureNotification } from '../../utils';

export default error => {
  const { status } = error;
  console.log('API Error:', error)

  // TODO: wire up specific actions to respond to specific HTTP status error codes
  switch (status) {
    case 401:
    // Expired or bad access token
      showFailureNotification('You do not have acccess to this page');
      break;
    case 403:
    // Unauthorized
      showFailureNotification('You are unauthorized to view this page');
      break;
    // case 500:
    // case 503:
    // case 504:
    default:
      showFailureNotification();
    // show error modal
  }
  return error;
}