import {
  SET_ACCESS_TOKEN,
  //
  SET_USER_TOKEN,
} from '../actions';

const initialState = {
  userToken: null,
  accessToken: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCESS_TOKEN:
      return { ...state, accessToken: action.payload };
    case SET_USER_TOKEN:
      return { ...state, userToken: action.payload };
    default:
      return state;
  }
}