// Reducers
import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import LocationReducer from './LocationReducer';
import HealthPromptReducer from './HealthPromptReducer';

// Combine all reducers
const appReducer = combineReducers({
  auth: AuthReducer,
  location: LocationReducer,
  healthPrompt: HealthPromptReducer
})

// Parent Reducer
const rootReducer = (state, action) => {
  // console.log('REDUX:', action)
  return appReducer(state, action);
}

export default rootReducer;