import React from 'react';
import './ListDivider.scss';
import { Row, Container } from 'react-bootstrap';
import { Overlay } from '../../../common';

const ListDivider = ({ text }) => {

  return (
    <>
      <Container className="ListDivider">
        <Row>
          <label>{text}</label>
          <Overlay />
        </Row>
      </Container>

    </>
  );
};

export { ListDivider };