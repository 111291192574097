import React from 'react';
import './Card.scss';
import { Card as BootstrapCard } from 'react-bootstrap';

const Card = props => {
  const { content, idx } = props;
  return (
    <>
      <BootstrapCard
        className="Card"
        idx={idx}>
        {content}
      </BootstrapCard>
    </>
  );
};

export { Card };