export const GET_HEALTH_PROMPTS = 'GET_HEALTH_PROMPTS';
export const GET_HEALTH_PROMPTS_SUCCESS = 'GET_HEALTH_PROMPTS_SUCCESS';
export const GET_HEALTH_PROMPTS_FAILURE = 'GET_HEALTH_PROMPTS_FAILURE';
//
export const TOGGLE_HEALTH_PROMPTS_SMS = 'SET_HEALTH_PROMPTS_SMS';
export const TOGGLE_HEALTH_PROMPTS_SMS_SUCCESS = 'TOGGLE_HEALTH_PROMPTS_SMS_SUCCESS';
export const TOGGLE_HEALTH_PROMPTS_SMS_FAILURE = 'TOGGLE_HEALTH_PROMPTS_SMS_FAILURE';
//
export const SET_HEALTH_PROMPT_ACTION = 'SET_HEALTH_PROMPT_ACTION';
export const SET_HEALTH_PROMPT_ACTION_SUCCESS = 'SET_HEALTH_PROMPT_ACTION_SUCCESS';
export const SET_HEALTH_PROMPT_ACTION_FAILURE = 'SET_HEALTH_PROMPT_ACTION_FAILURE';
//
export const SEND_HEALTH_PROMPT_HISTORY_EMAIL = 'SEND_HEALTH_PROMPT_HISTORY_EMAIL';
export const SEND_HEALTH_PROMPT_HISTORY_EMAIL_SUCCESS = 'SEND_HEALTH_PROMPT_HISTORY_EMAIL_SUCCESS';
export const SEND_HEALTH_PROMPT_HISTORY_EMAIL_FAILURE = 'SEND_HEALTH_PROMPT_HISTORY_EMAIL_FAILURE';
//
export const SEND_HEALTH_PROMPT_LABOR_EMAIL = 'SEND_HEALTH_PROMPT_LABOR_EMAIL';
export const SEND_HEALTH_PROMPT_LABOR_EMAIL_SUCCESS = 'SEND_HEALTH_PROMPT_LABOR_EMAIL_SUCCESS';
export const SEND_HEALTH_PROMPT_LABOR_EMAIL_FAILURE = 'SEND_HEALTH_PROMPT_LABOR_EMAIL_FAILURE';
//
export const POST_FEEDBACK_PROMPTS = 'POST_FEEDBACK_PROMPTS';
export const POST_FEEDBACK_PROMPTS_SUCCESS = 'POST_FEEDBACK_PROMPTS_SUCCESS';
export const POST_FEEDBACK_PROMPTS_FAILURE = 'POST_FEEDBACK_PROMPTS_FAILURE';
//
export const RESET_FEEDBACK_FORM = 'RESET_FEEDBACK_FORM';

