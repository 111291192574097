import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-notifications-component/dist/theme.css';
import 'rc-checkbox/assets/index.css';
import './index.scss';

import App from './App';

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
