export default {
  // APP
  APP_name: 'Health Prompt',

  // <b></b> tags parse-able with the parse() plugin from react-html-parser

  APP_UNAUTHORIZED_MESSAGE: 'Unauthorized to view this Application',
  APP_UNDEFINED_LOCATION_MESSAGE: 'Please search for a location',
  // Navigation Tabs
  APP_TODAY_TAB: 'Today',
  APP_HISTORY_TAB: 'History',

  // Notifications
  APP_NOTIFICATION_SUCCESS: 'Success',
  APP_NOTIFICATION_FAILURE: 'Something went wrong, please try again later',

  // Home Page
  HOME_PAGE_FEEDBACK_TOOLTIP: 'Feedback',

  // Today Page
  TODAY_ALERT_INFO_MESSAGE: 'Below are any Team Members who answered <b>YES</b> to a health prompt at the Point of Sale or On Demand via the following url: ',
  TODAY_COPY_TEXT: 'Copy',
  TODAY_COPIED_TEXT: 'Copied',
  TODAY_AT_HOME_SMS_REMINDERS: 'On Demand SMS Reminders',
  TODAY_SMS_REMINDERS_LEGAL_DISCLOSURE: `Operators who choose to use Health Prompt On Demand should consider creating policies
                    and/or other communications for how Team Members should use the Prompt. As a reminder,
                    franchised Chick-fil-A Operators are responsible for ensuring compliance with all federal,
                    state and local legal requirements that apply to their businesses, including, but not limited
                    to, whether time spent by Team Members performing tasks within or outside the restaurant are compensable,
                    whether use of any work related tools or apps may result in Team Members incurring work-related expenses, etc.
                    Operators participating in the optional EPLI program offered through Lexington Insurance Company may utilize the
                    EPLI Helpline <a href="tel:1-800-408-9351">(1-800-408-9351)</a> for specific employment-related questions.`,
  NO_HEALTH_PROMPTS_MESSAGE: 'You have had no Health Prompt alerts today',

  // History
  HISTORY_ALERT_HISTORY_MESSAGE: `This alert history reflects <b>YES</b> for a rolling period of 90 days. This alert history is not a Sick Log.
                Operators should consider keeping a separate Sick Log and may reference the sample template provided on @Chick-fil-A Home or refer
                to other resources provided by their local Health Department. Operators with Employment Practices Liability Insurance
                ("ELP") coverage through Lexington may also direct question about OSHA and state or local law compliance to Lexington's toll-free helpline for policy holders at <a href="tel:800-408-9351">(1-800-408-9351).</a>`,
  HISTORY_PAST_LABEL: 'Past 90 Days',
  //
  EMAIL_90_DAY_BUTTON: 'Email 90 Day History',
  EMAIL_LABOR_REPORT_BUTTON: 'Email Health Prompt Labor Report',
  //
  CORRECTIVE_ACTION_ALERT_TITLE: 'Corrective Action Explanation',
  CORRECTIVE_ACTION_EXCLUDE: `<b>Exclude</b> means to prevent a Team Member from working in the Restaurant or entering the Restaurant.`,
  CORRECTIVE_ACTION_RESTRICT: `<b>Restrict</b> means to limit the activities of a Team Member so that there is no risk of spreading a disease that is transmissible through food and the Team
                        Member does not work with exposed food, clean equipment, utensils, linens, or unwrapped single-service or single-use articles.`,
  CORRECTIVE_ACTION_ERROR: `<b>Error</b> means a Team Member hit <b>YES</b> by mistake.`,
  CORRECTIVE_ACTION_ACCEPTABLE: `<b>Acceptable</b> means a Team Member answered <b>Yes</b> correctly but is able to work after further conversation.`,

  // Modal Popup
  SMS_INFO_MODAL_TITLE: 'On Demand SMS',
  SMS_INFO_MODAL_TEXT_1: 'Enabling text reminders will send Team Members a link to the Health Prompt On Demand 1 hour before their shift begins.',
  SMS_INFO_MODAL_TEXT_2: 'HotSchedules Required: Schedules are verified through this platform only.',
  SMS_INFO_MODAL_TEXT_3: 'Team Member Cell Phone Numbers: Reminders are sent to Team Members with cell phone numbers in HR Payroll.Note: Cell phone numbers must include area code.',
  SMS_INFO_MODAL_TEXT_4: ' Team Member Opt Out: Team Members have the option to opt out of Text Reminders after they complete Health Prompt On Demand.',

  // Feedback Modal
  FEEDBACK_MODAL_TITLE: 'Feedback',
  FEEDBACK_MODAL_SUBTITLE: 'Send the development team some words of encouragement or suggestions for improvement',
  FEEDBACK_MODAL_FEEDBACK_PLACEHOLDER: 'Enter Feedback',
  FEEDBACK_MODAL_RATE_TITLE: 'How would you rate this tool?',
  FEEDBACK_MODAL_DISCLAIMER: 'Note: This should not be used as a way to report bugs or issues with the app. Please contact CFA Help if something is broken or not working as intended.',
  FEEDBACK_MODAL_EMAIL_PERMISSION: `It's okay to contact me about my feedback`,
  FEEDBACK_MODAL_FEEDBACK_LABEL: 'Feedback Comments',
  FEEDBACK_MODAL_EMAIL_LABEL: 'Contact email address',
  FEEDBACK_MODAL_SUBMIT_BUTTON: 'Submit Feedback',
  FEEDBACK_MODAL_CLOSE_BUTTON: 'Close',
  FEEDBACK_MODAL_SUBMIT_SUCCESS: 'Submission Success!',

  // Labor Report Modal
  LABOR_REPORT_MODAL_TITLE: 'Select Labor Report Date Range',

  // Health Prompt Alerts
  HEALTH_PROMPT_ALERT_ACTION_TYPE: 'Action Taken:',
  HEALTH_PROMPT_ALERT_ACTION_ERROR: 'Error',
  HEALTH_PROMPT_ALERT_ACTION_RESTRICTED: 'Restricted',
  HEALTH_PROMPT_ALERT_ACTION_EXCLUDED: 'Excluded',
  HEALTH_PROMPT_ALERT_ACTION_ACCEPTABLE: 'Acceptable',
  HEALTH_PROMPT_ALERT_AT_HOME_LABEL: 'On Demand',
  HEALTH_PROMPT_ALERT_POS_LABEL: 'Point of Sale',
  HEALTH_PROMPT_ALERT_CHANGE_BUTTON: 'Change',
  HEALTH_PROMPT_ALERT_CANCEL_BUTTON: 'Cancel',

  // History Prompt Alert List
  HEALTH_PROMPT_LIST_MESSAGE: `A team member responded <b>YES</b> to the health prompt at:`,



}