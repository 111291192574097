import React, { Component } from 'react';
import { connect } from 'react-redux';
import I18n from '../../../../i18n/utils';
import { parseHTML } from '../../../utils';
import './TodayPage.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { Card, PrimaryButton, ListDivider, ModalPopup } from '../../../components/common';
import HealthPromptAlert from '../../../components/HealthPromptAlert/HealthPromptAlert';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { showSuccessNotification } from '../../../utils';
import { toggleAtHomeSMSOptions } from '../../../actions';
import Switch from 'react-switch';
import linkIcon from '../../../assets/icons/link-icon.svg';

class TodayPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      healthPrompts: null,
      showSMSInfoModal: false,
      atHomeSMS: false,
      clipboardActive: false,
      userToken: props.userToken
    };

    this.toggleClipboardButton = this.toggleClipboardButton.bind(this);
    this.toggleSwitch = this.toggleSwitch.bind(this);
    this.toggleSMSInfoModal = this.toggleSMSInfoModal.bind(this);
    this.closeSMSInfoModal = this.closeSMSInfoModal.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    let update = {};

    // Update User Token
    if (props.userToken !== state.userToken) {
      update.userToken = props.userToken;
    }

    // Update Alerts
    if (props.alerts !== state.alerts) {
      update.alerts = props.alerts;
    }

    // Update Health Prompts
    if (props.healthPrompts !== state.healthPrompts) {
      update.healthPrompts = props.healthPrompts;
    }

    // Update At Home SMS
    if (props.atHomeSMS !== state.atHomeSMS) {
      update.atHomeSMS = props.atHomeSMS;
    }

    // Update Loading
    if (props.loading !== state.loading) {
      update.loading = props.loading;
    }

    return update;
  }

  toggleClipboardButton() {
    this.setState({ clipboardActive: true });
    showSuccessNotification('Link Copied');
    setTimeout(() => {
      this.setState({ clipboardActive: false });
    }, 3000);
  }

  toggleSwitch() {
    this.props.toggleAtHomeSMSOptions(this.state.atHomeSMS);
  }

  toggleSMSInfoModal() {
    this.setState({ showSMSInfoModal: true });
  }

  closeSMSInfoModal() {
    this.setState({ showSMSInfoModal: false });
  }

  async componentDidUpdate() {
  }

  render() {
    return (
      <Container className="TodayPage">
        <Card className="Card"
          content={
            <>
              {
                this.state.healthPrompts &&
                <div>
                  <Row>
                    <p>{parseHTML(I18n.t('TODAY_ALERT_INFO_MESSAGE'))}</p>
                  </Row>
                  <Row className="link-row">
                    <Col xs="7">
                      <a href={this.state.healthPrompts.atHomeUrl}>{this.state.healthPrompts.atHomeUrl}</a>
                    </Col>
                    <Col xs="5">
                      <CopyToClipboard
                        text={this.state.healthPrompts.atHomeUrl}
                        className="clipboard-container">
                        <PrimaryButton
                          row
                          outline
                          text={`${this.state.clipboardActive ? I18n.t('TODAY_COPIED_TEXT') : I18n.t('TODAY_COPY_TEXT')}`}
                          onClick={this.toggleClipboardButton}
                          icon={linkIcon}
                        />
                      </CopyToClipboard>
                    </Col>
                  </Row>
                </div>
              }
            </>
          }>
        </Card>
        {
          this.state.userToken &&
          this.state.userToken.isOperator &&
          this.state.healthPrompts &&
          this.state.healthPrompts.atHomeSMS !== null &&

          <Card
            content={
              <>
                <Row className="switch-card-container">
                  <Col xs="7" className="switch-title">
                    <h5>{I18n.t('TODAY_AT_HOME_SMS_REMINDERS')}</h5>
                  </Col>
                  <Col xs="5" className="switch-container">
                    <Row className="switch-row">
                      <Switch
                        className="toggle-switch"
                        onChange={this.toggleSwitch}
                        checked={this.state.atHomeSMS}
                        height={22}
                        width={38}
                        disabled={this.state.loading}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        onColor={'#3eb1c8'}
                        activeBoxShadow={"0 0 2px 3px #3bf"}
                        handleDiameter={18}
                      />
                      <img
                        alt=""
                        src={require('../../../assets/icons/info-icon.svg')}
                        className="info-icon"
                        onClick={this.toggleSMSInfoModal}
                      />
                    </Row>
                    {
                      this.state.showSMSInfoModal &&
                      <ModalPopup
                        type="sms-info-modal"
                        onClose={this.closeSMSInfoModal}
                      />
                    }
                  </Col>
                </Row>
                {
                  this.state.atHomeSMS &&
                  <div className="sms-text-container">
                    <hr className="divider" />
                    <p>{parseHTML(I18n.t('TODAY_SMS_REMINDERS_LEGAL_DISCLOSURE'))}</p>
                  </div>
                }
              </>
            }
          />
        }
        {
          (
            this.state.healthPrompts == null ||
            !this.state.healthPrompts.length
          ) &&
          <>
            <ListDivider text={'Alerts'} page={'today'} showIcon />
            {
              this.state.healthPrompts &&
              this.state.healthPrompts.today &&
              <>
                {
                  this.state.healthPrompts.today.length ?
                    <Card content={
                      this.state.healthPrompts.today[0].times.map((alert, idx) => (
                        <HealthPromptAlert alert={alert} idx={idx} />
                      ))
                    }>
                      />
                    </Card>
                    :
                    <Card
                      content={
                        <h6>{I18n.t('NO_HEALTH_PROMPTS_MESSAGE')}</h6>
                      }
                    />
                }
              </>
            }
          </>
        }
      </Container>
    )
  }
};

const mapStateToProps = state => {
  const { userToken } = state.auth;
  const { loading, healthPrompts, atHomeSMS } = state.healthPrompt;
  return { userToken, loading, healthPrompts, atHomeSMS };
}

export default connect(
  mapStateToProps,
  { toggleAtHomeSMSOptions }
)(TodayPage);